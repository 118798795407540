import gql from 'graphql-tag';

export const GetPharmacyForFamilyByNpiQuery = gql`
  query GetPharmacyForFamilyByNpi($npi: String!) {
    pharmacy(npi: $npi) {
      contactPerson {
        firstName
        lastName
      }
      name
      ncpdp
      npi
      family
      pharmacyChain {
        npi
        name
      }
    }
  }
`;
